.sidemenu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 410px;
  width: 100%;

  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 900;
    color: $main-color;
  }

  ul {
    border-left: 1px solid #dde1e6;
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex;
    align-items: center;
  }

  a {
    min-height: 32px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    color: $text-color-grey;

    @include hover-focus {
      color: $main-color;
    }

    &.is-done {
      border-left: 1px solid #d01481;
    }
  }
}

.sidemenu__current {
  min-height: 32px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  color: $main-color;
  border-left: 1px solid $main-color;
}

@media (max-width: $tablet-width) {
  .sidemenu {
    max-width: 100%;
    gap: 14px;

    h2 {
      font-size: 28px;
      line-height: 36px;
    }

    ul {
      border-left: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;
      margin: 0 -15px;
      max-width: fit-content;
      overflow-x: auto;
      padding: 12px 15px;
      counter-reset: step;

    }

    li {
      position: relative;
      display: flex;
      align-items: center;

      &::after {
        position: absolute;
        content: "";
        height: 1px;
        width: 30px;
        background-color: #D1D1D6;
        right: -35px;

      }

      &::before {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 28px;
        height: 28px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 50%;
        background-color: $color-default-white;
        color: #FF48AF;
        counter-increment: step;
        content: counter(step);
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.12);
      }

      &.current::before {
        background-color: #FF48AF;
        color: $color-default-white;
      }
    }

    a,
    .sidemenu__current {
      padding-left: 40px;
      white-space: nowrap;
      position: relative;
      display: flex;
      align-items: center;
      color: #666;


    }

    .sidemenu__current {
      color: #ff66b2;
      font-weight: 600;
      border: none;

      &::before {
        background-color: #ff66b2;
        color: #fff;
      }
    }

    a.is-done {
      color: #666;
      font-weight: normal;
      border: none;

      &::before {
        background-color: #ff66b2;
        color: #fff;
      }
    }
  }
}