.map__container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 24px;
  position: relative;
}

.map__main {
  max-width: 847px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.map__options {
  display: flex;
  flex-direction: column;
  gap: 30px;

  p {
    font-weight: 500;
    color: #9b99a1;
  }
}

.map__address {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.map__select {
  min-height: 66px;
  border: 1px solid #dde1e6;
  background-color: #f2f2f7;
  border-radius: 10px;
  padding: 20px 24px;
  appearance: none;
  max-width: 340px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  color: $text-color-grey;
  background-image: url('../images/svg/bird.svg');
  background-size: 12px 6px;
  background-repeat: no-repeat;
  background-position: right 30px center;

  &:last-child {
    max-width: 483px;
  }
}

.map__wrapper {
  max-width: 100%;
  min-height: 501px;
  border-radius: 16px;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.25);
  position: relative;

  img {
    position: absolute;
    content: '';
    width: 100%;
    object-fit: cover;
  }

  ymaps {
    border-radius: 16px;
  }

  ymaps .ymaps-2-1-79-map-copyrights-promo {
    display: none;
  }

  ymaps .ymaps-2-1-79-balloon__content > :first-child {
    height: 100% !important; 
}
}

.map__buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;

  a,
  button {
    max-width: 49%;
    width: 100%;
  }

  button {
    display: none;
  }
}

.ymaps-2-1-79-balloon {
  max-width: 300px !important;
}

.map__baloon {
  border-radius: 20px;
  box-sizing: border-box;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 12px;
  max-width: 250px;
  min-height: fit-content;
  min-width: 250px;
  padding: 12px;
}

.map__baloon-title {
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 4px;
}

.map__baloon-addr {
  color: $main-color;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.map__baloon-text {
  line-height: 16px;
  margin: 4px 0;
}

.map__baloon-subtext {
  font-weight: 700;
  margin: 16px 0;
}

@media (max-width: $tablet-width) {
  .map__container {
    flex-direction: column;
  }

  .map__main {
    max-width: 100%;
  }

  .map__select {
    max-width: 35%;

    &:last-child {
      max-width: 65%;
    }
  }

  .map__buttons {
    button {
      display: block;
    }
  }
}

@media (max-width: $mobile-width) {
  .map__container {
    gap: 16px;
  }

  .map__address {
    flex-direction: column;
    gap: 16px;
  }

  .map__main {
    gap: 16px;
  }

  .map__options {
    gap: 16px;
  }

  .map__select {
    max-width: 100%;

    &:last-child {
      max-width: 100%;
    }
  }

  .map__wrapper {
    min-height: 255px;

    img {
      height: 100%;
    }
  }

  .map__buttons {
    gap: 16px;

    a,
    button {
      max-width: 100%;
    }

    .map__next {
      display: none;
    }
  }
}
