@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SFProDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

