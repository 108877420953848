.prod__details {
  height: 100%;
  max-height: 880px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  touch-action: auto;
}

.prod__details-element {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    color: $text-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 18px;
    color: #242424;
    cursor: pointer;
  }
}

.prod__details-list {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  li {
    font-size: 12px;
    line-height: 14px;
    color: #848896;
  }

  &.result__components {
    li {
      color: $color-default-white;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
}