// Colors
$color-default-black: #000000;
$color-default-white: #ffffff;
$text-color: #333333;
$text-color-second: #18191e;
$text-color-grey: #9b99a1;
$main-color: #FF48AF;
$main-color-secondary: #f49ac1;
$color-error: #d70f0f;

// Typography
// переменная используемая в html для подключения скейлинга
$fs-default: 18px;
$lh-default: 22px;
$fw-default: 400;
$ff-geo: 'SF Pro', 'Arial', sans-serif;

// Animation
$tf-default: ease;
$trans-default: 0.3s $tf-default;
$trans: scale(0.9);
// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);
$trans-600: 0.6s $tf-default;

// Viewports
$mobile-width: 768px;
$tablet-width: 1200px;

// Retina
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
