.recs__container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 24px;
  position: relative;
}

.recs__main {
  max-width: 847px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.recs__qr {
  display: flex;
  gap: 32px;
  align-items: flex-start;
  background-color: #f0f1ff;
  padding: 32px;
  border-radius: 16px;
  justify-content: center;

  img {
    min-width: 153px;
    min-height: 150px;
    border-radius: 8px;
    object-fit: contain;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: flex-start;
    max-width: 300px;
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    color: #242424;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #848896;
  }
}

.recs__results {
  width: calc(100% + 40px);
  margin-left: -20px;
}

.recs__results-wrapper {
  display: flex;
  gap: 24px;
  align-items: start;
  padding: 0 20px;
}

.recs__photo {
  max-width: 50%;
  min-width: 406px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.recs__photo-cont {
  border-radius: 16px;
  height: 100%;
  min-width: 406px;
  overflow: hidden;
  position: relative;
}

.recs__photo-slider {
  height: 48px;
  width: 48px;
  background-color: $color-default-white;
  border-radius: 50%;
  cursor: ew-resize;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url("../images/svg/recs-photo.svg");
  background-repeat: no-repeat;
  background-size: 34px auto;
  background-position: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: "";
    display: block;
    height: 100vh;
    left: 22px;
    transform: translate3d(0, -50%, 0);
    width: 4px;
    background-color: #fff;
    position: absolute;
    top: 50%;
  }
}

.recs__photo-img {
  height: 100%;
  min-height: 329px;
  overflow: hidden;
  width: auto;

  img {
    display: block;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    width: 406px;
    max-width: unset;
    user-select: none;
    image-orientation: from-image;
  }
}

.recs__photo-over {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.recs__photo-text {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  margin-left: 20px;
  position: absolute;
  text-align: center;
  top: 24px;
  width: 90%;

  span {
    background-color: hsla(0, 0%, 100%, 0.8);
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 12px;
  }

  span:last-child {
    background-color: rgba(208, 20, 129, 0.8);
    color: $color-default-white;
  }
}

.recs__compare {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
    color: $main-color;
  }

  p {
    color: $text-color;
  }

  span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #9b99a1;
  }
}

.recs__compare-alert {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f2f2f7;
  border-radius: 16px;
  padding: 16px;

  span {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: $main-color;
    gap: 16px;

    &::before {
      content: "";
      width: 40px;
      height: 40px;
      background-color: $main-color;
      border-radius: 12px;
      background-image: url("../images/svg/recs-alert.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      left: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: $text-color;
    font-weight: 500;
  }
}

.recs__shop {
  background-color: #f2f2f7;
  border-radius: 16px;
  min-height: 126px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: $text-color;
  }

  a {
    font-size: 14px;
    line-height: 17px;
    color: $main-color;
  }

  p {
    font-size: 18px;
    line-height: 22px;
    color: $text-color;
  }
}

.recs .recs__shop a {
  &::before {
    display: none;
  }

  &:hover,
  &:focus {
    color: $text-color;
  }
}

.recs__total {
  box-shadow: 0px 0px 6px 1px rgba(163, 163, 163, 0.55);
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    dt {
      font-size: 18px;
      line-height: 24px;
      color: #a3a3a3;
    }

    dd {
      font-size: 18px;
      line-height: 24px;
      color: $text-color;
      font-weight: 500;
    }
  }

  dl:first-child {
    dt {
      font-size: 24px;
      line-height: 30px;
      font-weight: 500;
      color: $text-color;
    }

    dd {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: $text-color;
    }
  }

  .recs__total-btn {
    color: $color-default-white;
    display: flex;
    justify-content: center;
  }
}

.recs__total-btn.is-disabled {
  background-color: rgb(163, 163, 163, 0.4);
  cursor: default;

  &:hover,
  &:focus,
  &:active {
    background-color: rgb(163, 163, 163, 0.4);
    cursor: default;
    color: $color-default-white;
    transform: none;
    outline: none;
  }
}

.sidemenu .recs__total-btn.is-disabled {
  &:hover,
  &:focus,
  &:active {
    background-color: rgb(163, 163, 163, 0.8);
    cursor: default;
    color: $color-default-white;
    transform: none;
    outline: none;
  }
}

.recs__text {
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    font-size: 14px;
    line-height: 17px;
    color: $text-color;
  }
}

.recs__filter {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-bottom: 20px;
  background-color: $color-default-white;
}

.recs__filter-text {
  line-height: 24px;
  font-weight: 900;
  color: $text-color;
  display: none;
  margin: 0 0 24px;

  & b {
    color: $main-color;
    font-weight: 900;
  }
}

.recs__filter-btn {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: start;
  max-width: 150px;
  width: fit-content;
  padding: 7px 20px;
  min-height: 36px;
  border-radius: unset;
  background-color: transparent;
  font-size: 18px;
  line-height: 26px;
  color: $main-color;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  padding: 12px;

  & svg {
    width: 20px;
    height: 16px;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: $main-color;
    color: $color-default-white;

    svg {
      color: $color-default-white;
    }
  }

  &:not(:disabled):active {
    transform: scale(90%);
  }

  &:disabled {
    opacity: 90%;
    cursor: crosshair;
  }
}

.recs__filter-btn.is-active {
  border-color: $text-color;
  color: $text-color;

  & svg {
    color: $text-color;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border-color: $main-color;
    color: $main-color;

    svg {
      color: $main-color;
    }
  }

  &:not(:disabled):active {
    transform: scale(90%);
  }

  &:disabled {
    opacity: 90%;
    cursor: crosshair;
  }
}

.recs__prods {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.recs__care {
  h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    color: $main-color;
    padding-left: 56px;
    margin-bottom: 15px;

    &::before {
      position: absolute;
      content: "";
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &-morning h2::before {
    background-image: url("../images/svg/recs-morning.svg");
  }

  &-evening h2::before {
    background-image: url("../images/svg/recs-evening.svg");
  }

  &-addit h2::before {
    background-image: url("../images/svg/recs-addit.svg");
  }

  .swiper {
    padding: 10px;
  }
}

.recs__controls {
  button {
    border: none;
    background-color: transparent;
    margin-top: 0;
  }

  button.swiper-button-next::after {
    margin-right: -20px;
    color: $main-color;
  }

  button.swiper-button-prev::after {
    margin-left: -20px;
    color: $main-color;
  }
}

@media (max-width: $tablet-width) {
  .recs__container {
    flex-direction: column;
    padding-bottom: 220px;
  }

  .recs__main {
    max-width: 100%;
  }

  .recs__total {
    position: absolute;
    bottom: 0;
    background-color: $color-default-white;
    width: 100%;

    .recs__total-btn::before {
      display: none;
    }
  }
}

@media (max-width: $mobile-width) {
  .recs__container {
    padding-bottom: 110px;
  }

  .recs__main {
    gap: 16px;
  }

  .recs__qr {
    align-items: flex-start;
    margin-bottom: 33px;

    div {
      align-items: start;
      gap: 14px;
    }

    img {
      min-width: 62px;
      min-height: 64px;
    }

    h2 {
      font-size: 18px;
      line-height: 24px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      text-align: start;
    }
  }

  .recs__results {
    display: flex;
    gap: 16px;
    align-items: start;
    padding: 55px 0 20px;
    background: linear-gradient(to right, #9DC9FA, #667DFF);
    border-radius: 0 0 12px 12px;
  }

  .recs__photo {
    max-width: 100%;
    min-width: 160px;
  }

  .recs__photo-cont {
    min-width: 160px;
  }

  .recs__photo-img {
    min-height: 120px;

    img {
      width: 160px;
    }
  }

  .recs__photo-text {
    top: -35px;
    margin: 0;
    left: 0;
    width: 100%;

    span {
      width: fit-content;
      font-size: 12px;
      line-height: 16px;
      color: $color-default-white;
      border-radius: 20px;
      padding: 4px 12px;
    }

    .recs__photo-before {
      background: linear-gradient(90deg, #ff7b8f 0%, #ff9f6b 100%);
    }

    .recs__photo-after {
      background: linear-gradient(90deg, #71e28b 0%, #50c9da 100%);
    }
  }

  .recs__compare {
    gap: 8px;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;

    h2 {
      text-align: start;
      font-size: 20px;
      line-height: 26px;
      color: $color-default-white;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      color: $color-default-white;
    }
  }
  .recs__total {
    display: none;
  }

  .recs__photo-slider {
    width: 24px;
    height: 24px;
    background-size: 20px auto;

    &::before {
      left: 10px;
    }
  }

  .recs__text {
    position: absolute;
    bottom: 0;

    p {
      font-size: 14px;
      line-height: 17px;
      color: $text-color;
    }
  }

  .recs__filter {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin-bottom: 0;
  }

  .recs__filter-text {
    line-height: 24px;
    font-weight: 900;
    color: $text-color;
    display: none;
    margin: 0 0 24px;

    & b {
      color: $main-color;
      font-weight: 900;
    }
  }

  .recs__filter-btn {
    max-width: 100%;
    width: 100%;
  }

  .recs__prods {
    gap: 0;
  }

  .recs__care {
    padding: 24px 0;
    width: calc(100% + 40px);
    margin-left: -20px;

    h2 {
      font-size: 18px;
      line-height: 24px;
      padding-left: 40px;
      margin-bottom: 10px;
      color: #242424;

      &::before {
        width: 24px;
        height: 24px;
      }
    }

    .swiper {
      padding: 5px;
    }

    &-morning {
      background-color: #F0F1FF;
    }

    &-evening {
      background-color: #D5D8FF;
    }

    &-addit {
      background-color: #FFF0FD;
    }
  }

  .recs__care-wrapper {
    padding: 0 20px;
  }

  .recs__controls {
    button {
      padding: 0;
      width: 20px;
      height: 40px;

      &::after {
        width: 100%;
        height: 100%;
        font-size: xx-large;
      }
    }
  }

  .recs__total-mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;

    dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      dt {
        font-size: 16px;
        line-height: 18px;
        color: #a3a3a3;
      }

      dd {
        font-size: 16px;
        line-height: 18px;
        color: $text-color;
        font-weight: 500;
      }
    }

    dl:first-child {
      dt {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: $text-color;
      }

      dd {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        color: $text-color;
      }
    }
  }
}
