.about__main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;

    span {
      color: #ff48af;
      font-weight: 700;
    }
  }

  img {
    min-width: 100px;
    height: auto;
    object-fit: cover;
  }
}
