.footer {
  background-image: url("../images/notwebp/footer-bg.png");
  background-size: cover;
  min-height: 240px;
  display: flex;
  align-items: flex-end;

  @include retina {
    background-image: url("../images/notwebp/footer-bg@2x.png");
  }
}

.footer__container {
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  align-items: center;
  min-height: 48px;
  flex-wrap: wrap;
  row-gap: 20px;

  span {
    font-size: 14px;
    color: #18191e;
    line-height: 18px;
    margin-top: auto;
  }
}

.footer__left {
  display: flex;
  gap: 23px;
  align-items: center;
}

.footer__partners {
  display: flex;
  gap: 23px;
  max-width: 200px;
  flex-wrap: wrap;
  min-width: fit-content;

  li {
    display: flex;
  }

  a {
    display: flex;

    @include hover-focus {
      transform: $trans;
    }
  }
}

.footer__text {
  display: flex;
  flex-direction: column;
  gap: 9px;

  span {
    color: #18191e;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    color: #848896;
    line-height: 18px;
  }
}

.footer .footer__diag {
  font-size: 14px;
  color: #848896;
  line-height: 18px;
}

@media (max-width: $tablet-width) {
  .footer {
    min-height: fit-content;
  }
}

@media (max-width: $mobile-width) {
  .footer {
    min-height: 213px;
  }

  .footer__container {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    align-items: flex-start;
    flex-direction: column;
    min-height: fit-content;
    gap: 20px;
  }

  .footer__left {
    display: flex;
    flex-direction: column;
    gap: 23px;
    align-items: flex-start;
  }
}
