.tests__container {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  gap: 24px;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "face makeup"
      "perfume hair";
    gap: 14px;
  }

  li {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 6px;
    padding: 12px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      content: "";
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    color: $color-default-white;
    font-weight: 600;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    color: $color-default-white;
    z-index: 100;
  }

  div {
    display: flex;
    align-items: flex-start;
    margin-top: auto;

    a {
      min-width: 34px;
      min-height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-default-white;
      border-radius: 50%;
      height: fit-content;
      z-index: 100;

      &.disabled {
        padding: 4px 12px;
        background-color: #d9d9d9;
        color: $color-default-white;
        border-radius: 16px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        min-height: 21px;
        cursor: not-allowed;
      }
    }
  }
}

.tests__type {
  &.face {
    background: linear-gradient(135deg, #ff7b8f 0%, #ff9f6b 100%);
    min-height: 198px;
    max-height: 198px;

    a {
      color: #ff7b8f;
    }

    &:hover a,
    &:focus a {
      background: linear-gradient(135deg, #ff7b8f 0%, #ff9f6b 100%);
      color: $color-default-white;
    }

    &::after {
      background-image: url("../images/tests-face@2x.png");
      min-width: 162px;
      min-height: 150px;
      transform: rotate(0);
      bottom: -17px;
      right: -35px;
    }

    a {
      &:not(.disabled):hover,
      &:not(.disabled):focus {
        background: linear-gradient(135deg, #ff7b8f 0%, #ff9f6b 100%);
        color: $color-default-white;
      }
    }
  }

  &.makeup {
    background: linear-gradient(135deg, #e7a6ff 0%, #ffa6ca 50%, #ff668e 100%);
    min-height: 148px;
    max-height: 148px;

    &::after {
      background-image: url("../images/tests-makeup@2x.png");
      min-width: 140px;
      min-height: 140px;
      transform: rotate(-25deg);
      bottom: -60px;
      right: -50px;
    }

    a {
      color: #ff48af;

      &:not(.disabled):hover,
      &:not(.disabled):focus {
        background: linear-gradient(
          135deg,
          #e7a6ff 0%,
          #ffa6ca 50%,
          #ff668e 100%
        );
        color: $color-default-white;
      }
    }
  }

  &.perfume {
    max-height: 148px;
    min-height: 148px;
    background: linear-gradient(135deg, #9dc9fa 0%, #667dff 100%);

    &::after {
      background-image: url("../images/tests-perfume@2x.png");
      min-width: 98px;
      min-height: 90px;
      transform: rotate(-8deg);
      bottom: -15px;
      right: -20px;
    }

    a {
      color: #7696ff;

      &:not(.disabled):hover,
      &:not(.disabled):focus {
        background: linear-gradient(135deg, #9dc9fa 0%, #667dff 100%);
        color: $color-default-white;
      }
    }
  }

  &.hair {
    margin-top: -50px;
    background: linear-gradient(
      to bottom,
      #d1d1d0 0%,
      #e4e4e4 45.5%,
      #ffffff 100%
    );
    min-height: 198px;
    max-height: 198px;

    &::after {
      background-image: url("../images/tests-hair@2x.png");
      min-width: 177px;
      min-height: 236px;
      bottom: 0;
      right: 0;
    }
  }
}

.tests .tests__tech {
  display: flex;
  gap: 13px;
  padding: 12px;
  background-color: $color-default-white;
  border-radius: 12px;
  box-shadow: 0px 0px 48px 0px rgba(118, 115, 118, 0.24);
  background: #fff0fd;
  align-items: center;

  img {
    min-width: 40px;
    object-fit: cover;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    color: #525568;
  }

  a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    background-color: transparent;
    color: #ff48af;
    cursor: pointer;
    font-weight: 500;

    span {
      color: #ff48af;
    }
  }
}
