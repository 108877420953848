.login {
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    content: '';
    background-image: url('../images/login-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 400px;
    z-index: 0;
    top: -140px;

    @include retina {
      background-image: url('../images/login-bg@2x.webp');
    }
  }
}

.login__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 130px 0;
  max-width: 412px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;

  h1 {
    font-size: 36px;
    line-height: 44px;
    color: $text-color-second;
    font-weight: 700;
  }

  button {
    width: 100%;
  }

  div {
    margin-bottom: 24px;
  }

  .login__error {
    display: none;
    color: $color-error;
    text-align: start;
  }
}

.login__instr {
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  p {
    text-align: center;
    color: $text-color;
    color: #848896;
  }

  p:last-child {
    font-size: 16px;

    b {
      color: $main-color;
    }
  }
}

.login__check {
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }

  input {
    display: none;
  }

  span {
    min-width: 28px;
    height: 28px;
    border: 2px solid $main-color;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
  }

  input:checked + span::before {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    width: 17px;
    height: 17px;
    background-color: $main-color;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }

  p {
    text-align: start;
    font-size: 14px;
    line-height: 17px;
    color: $text-color-second;
  }

  a {
    color: $main-color;

    @include hover-focus {
      padding-bottom: 1px;
      border-bottom: 1px solid $main-color;
    }

    &:active {
      transform: $trans;
    }
  }
}

.login__code {
  display: none;

  input {
    margin-bottom: 24px;
  }

  div {
    gap: 24px;
    font-size: 16px;
    line-height: 18px;
    color: $text-color-second;
  }

  span {
    color: $main-color;
  }
}

.login__error {
  font-size: 16px;
  line-height: 20px;
  color: $color-error;
}

@media (max-width: $mobile-width) {
  .login {
    overflow: auto;
    
    &::before {
      top: 0;
    }
  }

  .login__container {
    gap: 16px;
    padding: 70px 0;
    max-width: 100%;
    margin: 0;

    h1 {
      font-size: 24px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }

    div {
      margin-bottom: 16px;
    }
  }

  .login__check {
    label {
      gap: 20px;
    }

    p {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .login__code {
    input {
      margin-bottom: 16px;
    }

    div {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .login__error {
    font-size: 14px;
    line-height: 16px;
  }
}