// modal common
.modal {
  display: none;
  background-color: rgba(66, 66, 66, 0.7);
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.modal.is-opened {
  display: flex;
  z-index: 5;
}

.modal__container {
  animation: sidebarModalShow 0.15s ease-out;
  background-color: $color-default-white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: auto;
  height: 100vh;
  width: 100%;
  max-width: 35%;
  padding: 30px;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  min-height: calc(100vh + env(safe-area-inset-top));
}

.modal__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 30px;
    line-height: 36px;
  }

  button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include hover-focus {
      animation: bounce 1s ease-in-out infinite;
      animation-delay: 0.5s;
      color: $main-color;
    }

    &:active {
      transform: $trans;
    }
  }
}

.modal__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
}

// lang
.modal__lang {
  display: flex;
  flex-direction: column;
  gap: 30px;

  li {
    display: flex;
    height: fit-content;
  }

  a {
    width: 100%;
  }
}

// improve
.modal__improve {
  h3 {
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style: decimal;
    padding-left: 20px;
  }
}

// share
.modal__share {
  display: flex;
  flex-direction: column;
  gap: 20px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  button {
    width: 100%;
  }
}

.modal__copy {
  display: flex;
  justify-content: center;

  svg {
    animation: shareFadeIn 0.3s ease-in both;
  }

  svg:not(:first-child) {
    display: none;
  }

  &.-copied {
    svg:not(:first-child) {
      display: block;
    }

    svg {
      display: none;
    }
  }
}

// filters
.modal__filters {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
}

.modal__filters-price {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal__filters-title {
  color: $text-color;
  font-weight: 700;
}

.modal__filters-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.modal__filters-label,
.modal__filters-brand {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  line-height: 18px;
  max-width: 49%;
  width: 100%;

  & input {
    background-color: #f2f2f7;
    border: 1px solid #dde1e6;
    border-radius: 10px;
    padding: 10px 24px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #333333;
    width: 99%;
    margin: 0 auto;

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus {
      outline: 1px solid $main-color;
    }
  }
}

.modal__filters-brand {
  max-width: 100%;
}

.modal__filters-prod {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 100%;
  max-width: 100%;
  width: 100%;

  &:hover input:not(:disabled):checked + .modal__filters-check,
  &:focus input:not(:disabled):checked + .modal__filters-check {
    background-color: $main-color-secondary;
  }

  & input:disabled:checked + .modal__filters-check {
    background-color: #f2f2f7;
    outline: 4px solid #f2f2f7;
    border-color: #f2f2f7;
    outline-offset: -8px;
    cursor: default;
  }

  &:hover input:not(:disabled) + .modal__filters-check {
    border-color: $main-color-secondary;
  }

  &:focus input:not(:disabled) + .modal__filters-check {
    border-color: $main-color-secondary;
  }

  &:disabled + .modal__filters-check {
    border-color: #f2f2f7;
    cursor: default;
  }

  & input {
    display: none;

    &:checked + .modal__filters-check {
      background-color: $main-color;
      outline: 4px solid $color-default-white;
      outline-offset: -8px;
    }
  }
}

.modal__filters-check {
  border: 2px solid $main-color;
  border-radius: 8px;
  height: 28px;
  min-width: 28px;
}

.modal__filters-name {
  color: $text-color;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  text-transform: uppercase;
}

.modal__filters-brand-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 52vh;
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  touch-action: pan-y;
}

.modal__filters-btns {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  gap: 15px;

  button {
    min-height: auto;
    padding: 12px 20px;
    max-width: 49%;
    width: 100%;
  }
}
// details
.modal__detail {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;

  h2 {
    font-size: 20px;
    line-height: 26px;
    color: $text-color;
  }
}

.modal__detail-prod {
  display: flex;
  gap: 15px;

  img {
    min-width: 178px;
    max-height: 300px;
    object-fit: contain;
  }
}

.modal__detail-descr {
  display: flex;
  align-items: center;
  gap: 15px;

  dl {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  dt {
    font-weight: 700;
    color: $text-color;
  }

  dd {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #242424;
    margin: 0;
  }
}

.modal__detail-text {
  display: flex;
  flex-direction: column;
  gap: 15px;

  span {
    font-size: 22px;
    line-height: 26px;
    color: $main-color;
    font-weight: 900;
  }

  button {
    padding: 10px 30px;
    min-height: 42px;
  }
}

.prod__details-list.hidden {
  display: none;
}

.prod__details-arrow {
  cursor: pointer;
  display: inline-block;
  transition: transform 0.3s ease;
}

.prod__details-arrow.rotated {
  transform: rotate(180deg);
}

.modal__alter {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.modal__alter-card {
  .card__prod {
    gap: 10px;
    padding: 24px;
  }

  .card__change {
    padding: 10px 30px;
    min-height: 40px;
  }
}

.modal__filters-skin {
  flex-direction: column;
  gap: 10px;
}

.modal__filters-block {
  display: flex;
  flex-direction: column;
  gap: 10px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.modal__improve {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: #848896;
    text-align: center;
  }
}

@media (max-width: $tablet-width) {
  .modal__container {
    max-width: 40%;
    padding: 20px;
  }

  .modal__top {
    h2 {
      font-size: 22px;
      line-height: 26px;
    }

    button {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .modal__filters-name {
    font-size: 18px;
    line-height: 24px;
  }

  .modal__detail {
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .modal__detail-prod {
    img {
      min-width: 130px;
      object-fit: cover;
    }
  }

  .modal__detail-descr {
    display: flex;
    gap: 15px;

    dl {
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    dt {
      font-weight: 500;
      color: $text-color;
    }

    dd {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin: 0;
    }
  }

  .modal__detail-text {
    display: flex;
    flex-direction: column;
    gap: 15px;

    span {
      font-size: 22px;
      line-height: 26px;
      color: $main-color;
      font-weight: 900;
    }

    button {
      padding: 10px 30px;
      min-height: 42px;
    }
  }
}

@media (max-width: $mobile-width) {
  .modal__container {
    max-width: 100%;
    min-height: 330px;
    max-height: 90%;
    padding: 20px;
    height: 100%;
    margin-top: auto;
    border-radius: 12px 12px 0 0;
  }

  .modal__filters {
    top: 60px;
    max-width: 100%;
  }

  .modal__filters-brand-list {
    height: 45vh;
    touch-action: pan-y;
  }

  .modal__detail-prod img {
    max-height: 200px;
    object-fit: contain;
  }

  .modal__filters-block {
    ul {
      gap: 5px;
    }
  }
}
