.order__container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  position: relative;
  flex-wrap: wrap;
}

.order__main {
  max-width: 62%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.order__title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;

  h1 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
  }
}

.order__shop {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: center;

  span {
    color: $text-color-grey;
  }

  p {
    color: $text-color;
  }
}

.order__list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    color: $text-color;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  li {
    padding: 22px 24px;
    border-radius: 16px;
    border: 1px solid #dde1e6;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
    box-shadow: 0 0 6px 1px hsla(0, 0%, 64%, 0.55);
  }
}

.order__data {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 430px;
  width: 100%;

  img {
    min-width: 94px;
    height: 94px;
    object-fit: contain;
  }

  p {
    color: $text-color;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.2em * 4);
    transition: max-height 0.3s, -webkit-line-clamp 0.3s;

    &.is-opened {
      max-height: none;
      -webkit-line-clamp: unset;
      white-space: normal;
    }
  }
}

.order__price {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 105px;
  width: 100%;

  span {
    font-size: 22px;
    line-height: 27px;
    color: $text-color;
    font-weight: 500;
  }

  p {
    display: flex;
    width: 100%;
    padding: 9px 16px;
    border-radius: 8px;
    border: 1px solid #dde1e6;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: $text-color-grey;
    justify-content: center;
  }
}

.order__total {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 24px;
  box-shadow: 0px 0px 6px 1px rgb(208, 20, 129, 0.55);
  border-radius: 16px;
  color: $main-color;
  margin-top: 40px;

  dl {
    display: flex;
    margin: 0;
    justify-content: space-between;
    gap: 40px;
  }

  dt {
    color: $text-color-grey;
  }

  dd {
    margin: 0;
    color: $text-color-grey;
  }

  dl:last-child {
    dt,
    dd {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: $text-color;
    }
  }
}

.order__side {
  max-width: 410px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 700px;
}

.order__qr {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  background-color: rgba(246, 208, 230, 0.4);
  padding: 32px;
  border-radius: 16px;
  width: 100%;

  h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    color: $main-color;
    text-align: center;
  }

  p {
    color: $text-color;
    font-weight: 500;
  }
}

.order__links {
  display: flex;
  flex-direction: column;
  gap: 15px;

  a {
    width: 100%;
  }
}

@media (max-width: $tablet-width) {
  .order__container {
    display: flex;
    flex-direction: column;
  }

  .order__main {
    gap: 20px;
    order: 3;
    max-width: 100%;
  }

  .order__side {
    max-width: 100%;
    order: 2;
  }
}

@media (max-width: $mobile-width) {
  .order {
    position: relative;
    padding-top: 200px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: -80px;
      left: 0;
      min-width: 375px;
      width: 100%;
      min-height: 400px;
      background-image: url("../images/order-bg@2x.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .order__container {
    gap: 16px;
    padding: 0 0 20px 0;
  }

  .order__main {
    width: 100%;
    gap: 24px;
  }

  .order__title {
    align-items: center;
    margin-top: -160px;

    img {
      justify-self: center;
    }

    h1 {
      font-size: 22px;
      line-height: 28px;
      color: $main-color;
    }
  }

  .order__side {
    order: 1;
  }

  .order__shop {
    gap: 0;
  }

  .order__list {
    ul {
      gap: 0;
      border-radius: 16px;
    }

    li {
      padding: 16px;
      border-radius: 0;
      flex-direction: row;
      gap: 10px;
      box-shadow: none;
      gap: 3px;

      &:first-of-type {
        border-radius: 16px 16px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 16px 16px;
      }

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        color: #242424;
        max-width: 190px;
      }

      span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: #242424;
      }
    }
  }

  .order__data {
    max-width: 100%;
    order: 2;
    flex-direction: column;
    align-items: start;
  }

  .order__data-num {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    p {
      font-size: 18px;
      line-height: 24px;
      color: #242424;
      font-weight: 600;
    }
  }

  .order__qr {
    max-width: 100%;
    background-color: transparent;
    align-items: start;

    p {
      padding-left: 35px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        left: 0;
        color: #242424;
      }

      &:first-child::before {
        background-image: url("../images/svg/order-woman.svg");
      }

      &:nth-child(2)::before {
        background-image: url("../images/svg/order-phone.svg");
      }
    }

    img {
      margin: 0 auto;
    }
  }

  .order__links {
    display: none;
  }

  .order .fixed__bottom {
    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: $main-color;
      text-align: center;

      b {
        font-weight: 400;
        color: #242424;
      }
    }
  }
}
