.btn {
  border-radius: 8px;
  border: 1px solid $main-color;
  font-family: $ff-geo;
  background-color: transparent;
  color: $main-color;
  font-size: $fs-default;
  line-height: $lh-default;
  font-weight: $fw-default;
  min-height: 57px;
  text-align: center;
  cursor: pointer;
  padding: 17px 30px;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: $main-color;
    color: $color-default-white;
    border-color: $main-color;
  }

  &:not(:disabled):active {
    transform: $trans;
  }

  &:disabled {
    opacity: 10%;
    cursor: default;
  }
}

.btn--pink {
  border: none;
  font-family: $ff-geo;
  background-color: $main-color;
  color: $color-default-white;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: transparent;
    color: $main-color;
    outline: 1px solid $main-color;
  }
}

.btn--grey {
  border: none;
  font-family: $ff-geo;
  background-color: #f2f2f7;
  color: #9b99a1;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: $main-color;
    color: $color-default-white;
  }

  &:focus-visible {
    outline: none;
  }

  &:not(:disabled):active {
    transform: none;
  }
}

.btn--black {
  background-color: #242424;
  color: $color-default-white;
  border-color: #242424;
}

@media (max-width: $tablet-width) {
  .btn {
    font-size: 16px;
    line-height: 18px;
    min-height: 40px;
    padding: 10px 15px;
  }
}
